<template>
  <layout-auth-form
    :promo-text="$t('Lobby.title')"
    :loading="pending"
    @submit.prevent="enterToRoom"
  >
    <auth-input
      v-model.number="room"
      type="number"
      :placeholder="$t('Lobby.roomNumber')"
    />
    <auth-button
      :disabled="enterDisabled"
      type="submit"
      filled
    >
      {{ $t("common.login") }}
    </auth-button>
    <auth-button v-if="isLogoutButton" @click="exitUser">
      {{ $t("Lobby.logout") }}
    </auth-button>
    <template v-if="!!error" #error>
      <span class="error">{{ error }}</span>
    </template>
  </layout-auth-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { auth, landing, room } from "@/store/modules/store.namespaces";
import { LOGOUT } from "@/store/modules/auth/action-types";
import { TEMPLATE } from "@/store/modules/landing/getter-types";
import { ENTER_TO_ROOM } from "@/store/modules/common/action-types";
import { wsErrorType } from "@/utils/errors";
import AuthButton from "@/components/common/auth/AuthButton";
import LayoutAuthForm from "@/components/layouts/LayoutAuthForm";
import AuthInput from "@/components/common/auth/AuthInput";
/**
 * Страница Лоби для участника или монитора здесь происходит вход в комнату
 *
 * @vue-data {String} room - Тип иконки regular
 * @vue-data {String} error - Ошибка при заходе в комнату
 * @vue-data {Boolean} pending - Ожидние входа в комнату
 * @vue-computed {String} template - Настройки лендинга
 * @vue-computed {Boolean} isLogoutButton - При скрытом лендинге нельзя на него перейти
 * @vue-computed {Boolean} enterDisabled - Комната должна быть из 4 символов и выше
 */
export default {
  name: "ViewAuthLobby",
  components: {
    AuthInput,
    LayoutAuthForm,
    AuthButton,
  },
  data() {
    return {
      room: "",
      error: "",
      pending: false,
    };
  },
  computed: {
    ...mapGetters(landing, {
      template: TEMPLATE,
    }),
    isLogoutButton() {
      return !this.template.hide_landing;
    },
    enterDisabled() {
      return `${this.room}`.length < 4;
    },
  },
  watch: {
    room() {
      this.error = "";
    },
  },
  methods: {
    ...mapActions(auth, {
      logout: LOGOUT,
    }),
    ...mapActions(room, {
      enterToRoomAction: ENTER_TO_ROOM,
    }),
    async enterToRoom() {
      if (this.enterDisabled) return;
      this.pending = true;
      try {
        this.error = "";
        await this.enterToRoomAction({ room: this.room });
      } catch (error) {
        if (error.hasType && error.hasType(wsErrorType.ROOM_ENTER_ERROR_NO_GUEST_ALLOWED)) {
          this.error = this.$t("Lobby.unavailableToGuest");
        } else {
          this.error = this.$t("Lobby.errorUnexpected");
        }
      } finally {
        this.pending = false;
      }
    },
    exitUser() {
      this.logout();
    },
  },
};
</script>

<style scoped lang="less">
@ws-grey: #414141;
@ws-fs1: 16px;
@ws-lh1: normal;

.login {
  position: relative;
  margin: 0 auto;
  min-height: 100%;
  background-repeat: no-repeat;
  .error {
    display: block;
    margin: 10px auto;
  }
  .ch-room {
    margin-bottom: 40px;

    input {
      -webkit-appearance: none;
      border-radius: 0;
    }
  }
  .login-loader {
    margin-top: 15px;
  }
  .main-login-btn {
    margin-bottom: 15px;
  }
  .form-title.select-lang-form {
    text-align: center;
    padding-top: 5px;
    margin-bottom: 20px;
  }
  input[type="text"] {
    border: none;
    width: 100%;
    padding: 10px 2px;
    border-bottom: 1px solid @ws-grey;
    font-size: @ws-fs1;
    line-height: @ws-lh1;
    background: none;
    color: inherit;
  }
  input[type="password"] {
    border: none;
    width: 100%;
    padding: 10px 2px;
    border-bottom: 1px solid @ws-grey;
    font-size: @ws-fs1;
    line-height: @ws-lh1;
    background: none;
    color: inherit;
  }
  button.button.big {
    margin-top: 15px;
    width: 100%;
    max-width: 100%;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: var(--base-color);
    a {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  a.button.bottom {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: var(--base-color);
  }
}
.login {
  form {
    margin: 20px;
    width: 310px;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--login-form-color);
    border-radius: 5px;

    @media (max-width: 768px) {
      margin: 20px 5px;
    }
  }
}
.enterRoom {
  input[type="text"] {
    border: none;
    width: 100%;
    padding: 10px 2px;
    border-bottom: 1px solid @ws-grey;
    font-size: @ws-fs1;
    line-height: @ws-lh1;
    background: none;
    color: inherit;
  }
}

.social-prompt {
  text-align: center;
  margin-top: 25px;
  margin-bottom: -10px;
  font-size: 13px;
  color: #9c9c9c;
}

.ulogin-block {
  text-align: center;
  margin-top: 30px;
}

.error {
  text-align: center;
  color: #ff2416;
}
</style>
